@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

*{
    // margin: 80;
    font-family: 'Nunito', sans-serif;
}

:root{
    --tran-05: all 0.4s ease;
}

body{
    min-height: 100vh;
    background: #eef5fe;
}

.sidebar{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 270px;
    background: #fff;
    padding: 15px 10px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    transition: all 0.4s ease;
    z-index: 100;
}

.sidebar.close{
    width: calc(35px + 20px);
}

.nav_image{
    height: 35px;
    width: 35px;
    object-fit: cover;
}

.flex{
    display: flex;
    align-items: center;
}
.hidden{
    display: none;
}

.logo_items{
    gap: 8px;
}

.logo_name{
    font-size: 22px;
    color: #333;
    font-weight: 500px;
    margin-right: 150px;
    transition: all 0.3s ease;
}

.sidebar.close .logo_name,
.sidebar.close #lock-icon,
.sidebar.close #sidebar-close{
    opacity: 0;
    pointer-events: none;
}

#lock-icon,
#sidebar-close{
    padding: 10px;
    color: #4070f4;
    font-size: 25px;
    cursor: pointer;
}

#sidebar-close{
    display: none;
    color: #333;
}

.menu_container{
    margin-top: 40px;
    overflow-y: auto;
    height: calc(100% - 82px);
    overflow-x: hidden;
}

.menu_title{
    position: relative;
    height: 50px;
    width: 55px;
}

.menu_title .title{
    margin-left: 15px;
    transition: all 0.3s ease;
}

.sidebar.close .title{
    opacity: 0;
}

.menu_title .line{
}

.menu_title .line{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 3px;
    width: 20px;
    border-radius: 25px;
    background: #aaa;
}

.menu_title .line{
    opacity: 0;
}

.sidebar.close .line{
    opacity: 1;
}

.item{
    list-style: none;
}

.link{
    text-decoration: none;
    border-radius: 8px;
    margin-bottom: 8px;
    color: #707070;
    // height: 50px;
    // background-color: red;
}
.sub_menu_item {
    padding-left: 2rem;
    .link{
        i{
            height: 32px;
        }
    }
}
.ml-auto{
    margin-left: auto;
}
.link i{
    height: 50px;
    min-width: 60px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    // background: red;
}

.link span{
    white-space: nowrap;
}

.link:hover{
    color: #fff;
    background-color: #4070f4;
}

.sidebar .menu_container{
    height: calc(100% - 150px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.containerTrx{
    position: relative;
    left: 288px;
    height: 100vh;
    width: calc(100% - 288px);
    // background: red;
    transition: var(--tran-05);
}

.sidebar.close ~ .containerTrx{
    left: 74px;
    width: calc(100% - 74px);
}

@media screen and (max-width: 800px){
    // .sidebar{
    //     left: 0;
    //     z-index: 1000;
    // }

    // .sidebar.close{
    //     left: -100%;
    // }

    // #sidebar-close{
    //     display: block;
    // }

    // #lock-icon{
    //     display: none;
    // }
}
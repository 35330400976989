.card1{
    width: 300px;
    height: 260px;
    border-radius: 4px;
    box-shadow: 0px 1px 10px 1px black;
    background: white;
}

.upper-container{
    background-image: linear-gradient(to left, #69e067, #00d4ff);
    height: 100px;
}

.upper-container-c{
    background-image: linear-gradient(to left, #e7475c, #ff809b);
    height: 100px;
}

.image-container img{
    background: white;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    padding: 5px;
    transform: translate(95px, 45px);
}

.img{
    border: 2px solid white;
}

.low-container{
    margin-top: 60px;
    height: 100px;
    background: white;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
}

.low-container h3{
    margin: 0;
    padding: 0;
    color: #2f2d2e;
    font-weight: 800;
    font-size: 1.5rem;
}
*, html, body{
    margin: 0;
    padding: 0;
}

.bodyLogin{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom right, #ff966d, #fa538d, #89379c);
}

.container{
    width: 50vw;
    height: 60vh;
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: "login";
    box-shadow: 0 0 17px 10px rgb(0 0 0 / 30%);
    border-radius: 20px;
    background: white;
    overflow: hidden;

    .login{
        grid-area: login;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        background: white;

        .text-input{
            background: #e6e6e6;
            height: 40px;
            display: flex;
            width: 60%;
            align-items: center;
            border-radius: 10px;
            padding: 0 15px;
            margin: 5px 0;

            .icon{
                color: #7451f8;
            }

            input{
                background: none;
                border: none;
                outline: none;
                height: 100%;
                width: 100%;
                margin-left: 10px;
            }

            ::placeholder{
                color: #9a9a9a;
            }
        }

        h3.title {
            margin: 15px 0;
        }

        .login-button{
            width: 68%;
            padding: 10px;
            color: white;
            background: linear-gradient(#ff966d, #fa538d, #89379c);
            border: none;
            border-radius: 20px;
            cursor: pointer;
            margin-top: 10px;
        }
    }
}

.desing{
    grid-area: desing;
    display: none;
    position: relative;

    .rotate-45{
        transform: rotate(-45deg);
    }

    .pill-1{
        bottom: 0;
        left: -40px;
        position: absolute;
        width: 80px;
        height: 200px;
        background: linear-gradient(#ff966d, #fa538d, #89379c);
        border-radius: 40px;
    }

    .pill-2{
        top: -100px;
        left: -80px;
        position: absolute;
        width: 220px;
        height: 450px;
        background: linear-gradient(#ff966d, #fa538d, #89379c);
        border-radius: 200px;
        border: 30px solid #e2c5e2;
    }

    .pill-3{
        top: -100px;
        left: 160px;
        position: absolute;
        width: 100px;
        height: 200px;
        background: linear-gradient(#ff966d, #fa538d, #89379c);
        border-radius: 70px;
    }

    .pill-4{
        bottom: -180px;
        left: 220px;
        position: absolute;
        width: 120px;
        height: 300px;
        background: linear-gradient(#ff966d, #fa538d);
        border-radius: 70px;
    }
}

.dialog{
    .text-input{
        background: #e6e6e6;
        height: 40px;
        display: flex;
        width: 93%;
        align-items: center;
        border-radius: 10px;
        padding: 0 15px;
        margin: 5px 0;
    
        .icon{
            color: #7451f8;
        }
    
        input{
            background: none;
            border: none;
            outline: none;
            height: 100%;
            width: 100%;
            margin-left: 10px;
        }
    
        ::placeholder{
            color: #9a9a9a;
        }
    }

    .login-button{
        width: 100%;
        padding: 10px;
        color: white;
        background: linear-gradient(#ff966d, #fa538d, #89379c);
        border: none;
        border-radius: 20px;
        cursor: pointer;
        margin-top: 10px;
    }
}



@media (min-width: 900px){
    .container{
        grid-template-columns: 50% 50%;
        grid-template-areas: "desing login";
    }

    .desing{
        display: block;
    }
}

// pruebas passage
// passage-auth{
//     // --passage-container-background-color: #000;
//     --passage-container-max-width: 850;
// }


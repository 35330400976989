.input-wrapper {
    width: 97%;
    height: 2.5rem;
    border: none;
    border-radius: 10px;
    padding: 0 0 0 15px;
    box-shadow: 0px 0px 8px #ddd;
    background-color: white;
    display: flex;
    align-items: center;
}
  
input {
    background-color: transparent;
    border: none;
    height: 100%;
    font-size: 1.25rem;
    width: 98%;
    margin-left: 5px;
}
  
input:focus {
    outline: none;
}
  
#search-icon {
    color: royalblue;
}